@import url("https://v1.fontapi.ir/css/SFProDisplay");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: SF Pro Display, sans-serif;
}

.text-stroke {
  -webkit-text-stroke: 2px white;
}
